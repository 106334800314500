import "./App.css";
import Footer from "./Components/Footer";
import { NavbarMain } from "./Components/Navbar";
import MainRoutes from "./Pages/MainRoutes";

function App() {
  return (
    <div className="App">
      <NavbarMain />

      <MainRoutes />

      <Footer />
    </div>
  );
}

export default App;
