import React from "react";
import "../App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import  Searchart  from "./Topic";
import About from "./About";
import Connect from "./Connect";
import Privacy from "./Privacy";
import DoNotSell from "./DoNotSell";
import Latest from "./Latest";
import Unsubscribe from "./Unsubscribe";
import PostPreview from "./PostPreview";
import NotFound from "../Components/NotFound";
import Scrolltop from "../Components/Scrolltop";
import Tag from "../Components/Tag";
import Lazy from "../Components/Lazy";
import ArticleDesc from "./ArticleDesc";
import ContentCategory from "./ContentCategory";
import Author from "./Author";
import Searchlist from "./Searchlist";
import Sitemap from "./Sitemap";
const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/Latest" element={<Latest />}></Route>
        <Route path="/About" element={<About />}></Route>
        <Route path="/ContentCategory" element={<ContentCategory />}></Route>
        <Route exact path="/:cat_slug/:post_name" element={<Searchart />} />
        <Route path="/Unsubscribe" element={<Unsubscribe />}></Route>
        <Route path="/ArticleDesc" element={<ArticleDesc />}></Route>
        <Route path="/Tag" element={<Tag />}></Route>
        <Route exact path="/tag/:subcat" element={<Tag />} />
        <Route path="/Connect" element={<Connect />}></Route>
        <Route path="/opt-out-form" element={<DoNotSell />}></Route>
        <Route path="/sitemap" element={<Sitemap />}></Route>
        <Route path="/Author" element={<Author />}></Route>
        <Route exact path="/topic/:cat" element={<ContentCategory />} />
        <Route exact path="/topic/:cat/:subcat" element={<ContentCategory />} />
        <Route exact path="/search/:cat/:searchVal" element={<Searchlist />} />
        <Route
          exact
          path="/search/:cat/:searchVal/:subcat"
          element={<Searchlist />}
        />
        <Route path="/Privacy" element={<Privacy />}></Route>
        <Route path="/Scrolltop" element={<Scrolltop />}></Route>
        <Route path="/Lazy" element={<Lazy />}></Route>
        <Route path="*" element={<NotFound />} />

        {/* Post detail page preview  */}
        <Route
          path="/preview/:cat_slug/:post_name"
          element={<PostPreview />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
